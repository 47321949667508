import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ArticleType } from '../../../types/articleType';
import { CenterProductArticleType } from '../../../types/centerProductArticleType';
import { DocumentTypeLabel } from '../../../types/testType';
import { useAuth0Token } from '../hooks/useAuth0Token';
import { useDocument } from '../hooks/useDocument';
import Article from '../pages/Article';
import { NotFound } from '../pages/errors/NotFound';
import { LoaderPage } from '../pages/LoaderPage';
import { NafProductArticle } from '../pages/nafCenter/product';
import { TopicPageApp } from '../pages/TopicPageApp/TopicPageApp';
import { TopicPageAppPropertiesType } from '../../../types/CategoryAppType';
import { nafCenterBasePath } from './NafCenterRouter';

export const NafCenterTypeSwitch = ({ slug }: { slug: string }) => {
  const { simpleToken } = useAuth0Token();
  const { meta, data } = useDocument(slug, simpleToken);
  const location = useLocation();
  const history = useHistory();

  const locationStripped = location.pathname.replace(/\/$/, '').replace('naf-senter/', '');

  useEffect(() => {
    if (
      location.pathname.includes('naf-senter/') &&
      data &&
      history &&
      ((data as CenterProductArticleType).type === DocumentTypeLabel.nafCenterProductArticle ||
        (data as ArticleType).type === DocumentTypeLabel.nafnoArticle)
    ) {
      const redirectUrl = location.pathname.replace('naf-senter/', '');
      history.push(redirectUrl);
    }
  }, [location, data, history]);

  if (data) {
    // Check if article belongs in NAF Center tree
    const isNafCenterTopicPage = data.type === DocumentTypeLabel.topicPage && data.category?.slug === nafCenterBasePath;

    const isRootNafCenterCategoryArticle =
      (data as ArticleType).type === DocumentTypeLabel.nafnoArticle &&
      (data as ArticleType).category &&
      (data as ArticleType).category?.slug === nafCenterBasePath;

    const isNafCentersArticle =
      (data as ArticleType).type === DocumentTypeLabel.nafnoArticle &&
      (data as ArticleType).category &&
      (data as ArticleType).category?.slug === 'naf-senter';

    // Check if article parent equals current product name
    const isNafCenterProductSubArticle =
      (data as ArticleType).type === DocumentTypeLabel.nafnoArticle &&
      (data as ArticleType).parent &&
      locationStripped.includes(((data as ArticleType).parent?.slug || ' ').toLowerCase());

    // Check if article is a NAF Center Product article
    const isNafCentersProductArticle =
      (data as CenterProductArticleType).type === DocumentTypeLabel.nafCenterProductArticle;

    if (isRootNafCenterCategoryArticle || isNafCentersArticle || isNafCenterProductSubArticle) {
      // Common NAF Center article
      return <Article article={data as ArticleType} />;
    }
    if (isNafCentersProductArticle) {
      // NAF Center Product article
      return <NafProductArticle centerProductArticle={data as CenterProductArticleType} productSlug={slug} />;
    }
    if (isNafCenterTopicPage) {
      const topicPage = data as unknown as TopicPageAppPropertiesType;
      return <TopicPageApp data={topicPage.category} asArticleData={topicPage} />;
    }
    // Regardless of data - If the document doesnt belong in the NAF Center tree, render 404 page
    return <NotFound />;
  }
  if (!data && meta.isUpdating === true) {
    // If there is no data, but the document is loading -> render loader
    return <LoaderPage />;
  }
  if (!data && !meta.isUpdating) {
    return <NotFound />;
  }
  return null;
};
